<template>
  <v-app>
    <prase-core-app-bar />
    <prase-core-drawer />
    <prase-core-view />
    <!-- <prase-core-settings /> -->
  </v-app>
</template>

<script>
  export default {
    name: 'PRASEMapIndex',

    components: {
      PraseCoreAppBar: () => import('@/views/prasemap/components/core/AppBar'),
      PraseCoreDrawer: () => import('@/views/prasemap/components/core/Drawer'),
      PraseCoreView: () => import('@/views/prasemap/components/core/View'),
      // PraseCoreSettings: () => import('./components/core/Settings'),
    },
  }
</script>

<style>
</style>
